<template>
  <div>
    <p>
      My bio is Hello world
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>