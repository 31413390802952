<template>
  <div>
    {{info.publication_date}}
    {{info.place}}
  </div>
</template>

<script>
export default {
  props:['info']

}
</script>

<style scoped>

</style>