<template>
<!--    <navbar></navbar>-->
    <div class="center">
        <h2>The page not found</h2>
    </div>
</template>

<script>
// import navbar from "../navbar/navbar";
// export default {
//
//     components:{navbar}
// }
</script>

<style scoped>
h2{
    margin: 0;
    /*position: absolute;*/
    top: 40%;
    left: 60%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
</style>
